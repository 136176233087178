export const GET_METHOD = "GET";
export const POST_METHOD = "POST";
export const PUT_METHOD = "PUT";
export const DELETE_METHOD = "DELETE";

export const ACCESS_TOKEN = "access_token";
export const SAVE_ID = "save_id";

export const ENABLE_STATUS = "ENABLE";
export const DISABLE_STATUS = "DISABLE";

export const PAGE_PER_SIZE = 50;

export const ROLE_APP_ADMIN = "ROLE_APP_ADMIN";
export const ROLE_APP_MANGER = "ROLE_APP_MANAGER";

// 정규식
export const EMAIL_REX = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const PW_REX = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
export const KOR_REX = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
// export const PHONE_REX = /^\d{2,3}\d{3,4}\d{4}$/;
export const PHONE_REX = /^01([0|1|6|7|8|9])([0-9]{3,4})([0-9]{4})$/;
export const UPPER_CASE_REX = /[A-Z]/;
export const LOWER_CASE_REX = /[a-z]/;
export const SPC_REX = /[~!@#$%^&*()+|<>?:{}_]/;
export const START_NUMBER_REX = /^[0-9]/;

export const AES_SECREY_KEY = "mbaas_secret_key$%^%&#$@#@#$&^$%#$";

export const SERVICE_AGREE = `AiApp 서비스 이용약관

본 개인정보처리방침의 목차는 아래와 같습니다.


관계법령이 요구하는 개인정보처리방침의 필수 사항과 Ai.app 자체적으로 이용자 개인정보 보호에 있어 중요하게 판단하는 내용을 포함하였습니다.


제1장 총칙

제1조(목적)
제2조(정의)
제3조(약관의 명시)
제4조(약관의 동의)
제5조(약관의 변경)
제6조(약관의 해석)

제2장 이용계약의 체결 및 약관 동의

제7조(이용신청 및 방법)
제8조(이용신청의 승낙과 거절)

제3장 계약 당사자의 의무

제9조(Ai.app의 의무)
제10조(앱 창작자의 의무)

제4장 서비스의 이용

제11조(서비스의 제공 및 변경)
제12조(서비스 이용 요금)
제13조(이용요금의 청구와 지급)
제14조(이용요금의 정산 및 반환)
제15조(서비스 이용의 제한 및 정지)
제16조(서비스 제공의 중단)

제5장 서비스의 이용기간 및 종료

제17조(서비스 이용기간 자동갱신)
제18조(앱 창작자의 서비스 해제 및 해지)
제19조(Ai.app의 서비스 해제 및 해지)

제6장 Ai.app의 면책 등

제20조(Ai.app의 면책)
제21조(앱 창작자에 대한 통지)
제22조(양도 등의 제한)
제23조(준거법)

제1장 총칙


제1조(목적)

이 앱 창작자 이용약관(이하 “본 약관”)은 주식회사 엠바스(이하 “Ai.app”)가 제공하는 클라우드 기반 앱 개발 플랫폼 솔루션 “Ai.app” 및 부가서비스(이하 “본 서비스”)의 이용과 관련하여, Ai.app과 본 서비스를 이용하고자 하는 사업자(이하 “앱 창작자”) 간의 계약 관계에서 발생하는 권리와 의무, 그 밖에 필요한 기본적인 사항을 규정함을 목적으로 합니다.

제2조(정의)

본 약관에서 사용하는 용어의 정의는 아래와 같습니다.
① “본 서비스”라 함은 Ai.app이 제공하는 클라우드 기반의 앱 개발 플랫폼 솔루션을 말합니다.
② “Ai.app”이라 함은 본 서비스를 제공하는 사업자로서, 본 약관에서는 주식회사 엠바스를 지칭합니다.
③ “앱 창작자”라 함은 본 약관 상의 이용신청 절차에 따라 Ai.app과 유료 혹은 무료 서비스 이용계약을 체결하고 본 서비스를 이용하는 예비창업자, 개인사업자, 혹은 법인사업자를 말합니다.
④ “유료 서비스”라 함은 Ai.app이 제공하는 부가서비스 중 앱 창작자의 필요한 기능에 따라 이용기간 단위로 요금을 지불한 경우 이용할 수 있는 서비스를 의미합니다.
⑤ “정기결제”라 함은 “유료 서비스”를 일정 시기 및 일정 조건 하에서 반복적으로 거래하기 위하여 미리 등록한 결제수단으로 특정일에 자동으로 결제하는 결제 형태를 말합니다.
⑥ “정기결제형 유료 서비스”라 함은 유료 서비스 중 정기결제 형태로 결제가 이루어지고 이용기간이 자동으로 갱신되는 서비스를 말합니다.
⑦ "서비스 이용계약"이라 함은 유료 서비스 이용을 위하여 Ai.app과 앱 창작자 간 상호 동의하여 체결하는 개별 계약을 말합니다.
⑧ “최종이용자”라 함은 회원가입을 완료한 앱 창작자가 본 서비스 내에 개설한 앱에 초대하여 회원가입 및 합류를 완료하고 본 서비스를 이용하는 개인을 말합니다.
⑨ “앱 창작자 데이터”라 함은 앱 창작자 및 최종이용자가 Ai.app의 정보통신자원에 제출, 기록, 업로드 등의 형식으로 저장하는 정보로서 앱 창작자가 소유 또는 관리하는 정보를 말합니다.
⑩ "최종이용자 정보"라 함은 이름, 이메일 주소, 전화번호 등의 개인정보 등 본 서비스를 이용하는 특정 최종이용자를 식별할 수 있는 정보를 말합니다.
⑪ “결제정보"라 함은 유료 서비스를 사용하는 앱 창작자의 서비스 이용요금 지급과 관련하여 결제에 관련된 정보로, 결제 수단, 결제 내역 등을 말합니다.
⑫ "개별약관"이라 함은 본 서비스에 관하여 본 약관과는 별도로 ‘약관’, 가이드라인', ‘정책’, ‘방침’ 등의 명칭으로 Ai.app이 배포 또는 게시한 문서를 말합니다.

제3조(약관의 명시)

① ㈜엠바스는 앱 창작자가 본 약관, 개별약관의 내용과 ㈜엠바스의 상호, 회사소재지, 대표자의 성명, 사업자등록번호, 연락처 등을 쉽게 알 수 있도록 Ai.app이 운영하는 웹사이트 내의 적절한 장소에 게시합니다.
② ㈜엠바스는 앱 창작자가 Ai.app과 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 적절한 절차를 마련하여야 합니다.

제4조(약관의 동의)

① 앱 창작자는 본 약관의 규정에 따라 본 서비스를 이용해야 합니다. 앱 창작자는 회원가입시 본 약관과 개별약관에 대해 유효한 동의를 했을 경우에 한하여 본 서비스를 이용할 수 있습니다. 앱 창작자가 회원가입 후 본 약관의 내용을 확인하고 본 서비스를 실제로 이용하거나 개발 앱의 최종 가입자가 실제로 이용하도록 하는 경우에도 본 약관에 동의하는 것으로 간주됩니다.
② Ai.app은 본 약관 이외에 별도의 이용약관 및 정책을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 개별약관이 우선 적용되며, 다만 개별약관이 규율하지 않는 부분에 대해서는 본 약관이 적용됩니다.

제 5조(약관의 변경)

① Ai.app은 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
② Ai.app이 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 앱 창작자에게 불리한 약관의 개정의 경우에는 적용일자 30일 전에 공지하며, 이와 별도로 개정약관의 내용을 앱 창작자가 등록한 이메일 또는 서비스 내 기능을 통해 개별적으로 통지한다.
③ 앱 창작자가 전항에 따라 개정약관을 공지 또는 통지한 후에 앱 창작자가 30일 기간 내에 명시적으로 거부의 의사표시를 하지 않은 경우 약관의 개정사항에 대해 동의한 것으로 간주하며, 변경된 약관에 대한 정보를 알지 못하여 발생하는 앱 창작자의 피해에 대해 Ai.app는 책임을 지지 않습니다.

제6조(약관의 해석)

본 약관에서 정하지 아니한 사항과 본 약관의 해석에 관하여는 「클라우드컴퓨팅 발전 및 이용자 보호에 관한 법률」, 「개인정보 보호법」, 「신용정보의 이용 및 보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」과 관계 법령 또는 상관습에 따릅니다.

제2장 이용계약의 체결 및 약관 동의


제7조(이용신청 및 방법)

① 앱 창작자는 Ai.app”이 정한 이용신청 양식에 따라 본 서비스 제공에 필요한 필수 정보를 기입한 후 본 약관의 내용에 대하여 동의한다는 의사표시를 하여 본 서비스의 이용을 신청합니다. Ai.app은 이용신청에 필요한 개인정보의 항목과 그 처리목적을 앱 창작자가 확인할 수 있도록 개인정보 처리방침에 안내합니다.
② 앱 창작자는 제1항의 신청을 할 때에 본인의 실명 및 실제 정보를 기재하여야 합니다. 앱 창작자가 타인 또는 타사의 명의를 도용하거나 거짓 정보를 기재한 경우에는 본 약관에서 정한 권리를 주장할 수 없고, Ai.app은 이를 이유로 본 서비스 이용계약을 해제하거나 해지할 수 있습니다.
③ 14세 미만의 아동은 본 서비스를 이용할 수 없으며 14세 미만의 아동이 아닌 미성년자가 이용할 경우에는 법정대리인의 동의를 얻어야 하고, 구체적인 동의 절차는 Ai.app이 제공하는 방법에 따르며 이에 따라 발생하는 문제에 대하여 Ai.app은 책임지지 않습니다.
④ 유료 서비스를 이용하고자 하는 앱 창작자는 유료 서비스 요금제 및 결제 방법을 선택하고 결제정보를 입력하거나 개별 계약을 체결함으로써 유료 서비스의 이용을 신청할 수 있습니다.

제8조(이용신청의 승낙과 거절)

① 서비스 이용계약은 앱 창작자가 Ai.app에게 이용신청을 하고, Ai.app의 승낙의 통지가 앱 창작자에게 도달한 때에 성립합다. 다만, 앱 창작자가 본 서비스 이용을 위하여 Ai.app과 본 14조 약관과 별도의 개별계약을 체결한 경우, 계약의 성립시기는 별도의 서면·전자 계약서가 양측에 의해 날인되어 양측 모두 원본 혹은 사본을 교부받은 때로 합니다.
② Ai.app은 원칙적으로 앱 창작작자의 이용신청을 승낙합니다. 단, 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙하지 않을 수 있습니다.
1. 제7조(이용신청 및 방법) 제1항에 위반하여 이용을 신청한 경우
2. 앱 창작자가 이용요금을 납부하지 않은 경우
3. 앱 창작자가 Ai.app과 체결한 계약의 중대한 내용을 위반한 사실이 있는 경우
4. 14세 미만의 아동으로 확인된 경우
5. 14세미만의 아동이 아닌 미성년자가 법정대리인의 동의를 받지 않았거나 동의를 받은 사실을 확인할 수 없는 경우
6. 타인·타사의 신용카드, 유·무선 전화, 은행 계좌 등을 무단으로 이용하거나 도용하여 서비스 이용요금을 결제하는 경우
7. 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「저작권법」, 「개인정보 보호법」 및 그 밖의 관계 법령에서 금지하는 위법행위를 할 목적으로 이용신청을 하는 경우
8. 앱 창작자가 이 계약에 의하여 이전에 앱 창작자의 자격을 상실한 사실이 있는 경우
9. 그 밖에 제1호에서 제7호까지에 준하는 사유로서 승낙하는 것이 상당히 부적절하다고 판단되는 경우
③ Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있다.
그 밖에 제1호 또는 제2호에 준하는 사유로서 이용신청의 승낙이 곤란한 경우
④ 앱 창작자는 이용신청 시 기재한 필수정보가 변경되었을 경우 이메일, 고객센터 등을 통하여 그 내용을 Ai.app에게 지체 없이 알려야 하며, 그 필수정보가 개인 정보에 해당하는 경우 본 서비스 내 계정 설정 페이지를 통하여 직접 수정하여야 합니다.

제3장 계약 당사자의 의무


제9조(Ai.app의 의무)

① Ai.app은 「클라우드컴퓨팅 발전 및 이용자 보호에 관한 법률」 등 관련 법령을 준수하고, 앱 창작자가 서비스를 원활히 이용하고 최종이용자의 개인정보를 보호할 수 있도록 정보보호에 관한 기준을 갖추어야 합니다.
② Ai.app은 안정적인 서비스 제공을 위하여 정기적인 운영 점검을 실시할 수 있고, 이를 사전에 앱 창작자에게 통지하여야 합니다.
③ Ai.app은 장애로 인하여 정상적인 서비스가 어려운 경우에 이를 신속하게 수리 및 복구하고, 신속한 처리가 곤란한 경우에는 그 사유와 일정을 앱 창작자에게 통지하여야 합니다.
④ Ai.app은 적절한 수준의 보안을 제공하여야 하며, 개인정보의 유출 또는 제3자로부터의 권리 침해를 방지할 의무가 있습니다.
⑤ Ai.app은 앱 창작자가 본 서비스의 이용현황 및 대금내역을 알기 쉽게 확인할 수 있도록 조치하여야 합니다.

제10조(앱 창작자의 의무)

① 앱 창작자는 서비스를 이용하는 과정에서 본 약관 및 개별약관, 저작권법 등 관련 법령을 위반하거나 선량한 풍속, 기타 사회질서에 반하는 행위를 하여서는 아니 됩니다.
② 유료 서비스를 사용하는 앱 창작자는 이용계약에서 정한 날까지 요금을 납부하여야 하고, 연락처, 요금결제 수단 등 거래에 필요한 정보가 변경된 때에는 그 사실을 Ai.app에게 지체 없이 알려야 합니다.
③ Ai.app은 아이디와 비밀번호 등 서비스 접속정보에 대한 관리책임이 있으며, 앱 창작자 혹은 최종이용자의 주의의무 위반으로 인한 최종이용자 정보의 도용에 대해서는 Ai.app이 책임을 지지 않는다.
④ 앱 창작자는 이 계약의 규정, 이용안내 및 서비스와 관련하여 Ai.app으로부터 통지받은 제반사항을 확인하고, 합의된 사항을 준수하여야 합니다

제4장 서비스의 이용


제11조(서비스의 제공 및 변경)

① Ai.app은 앱 창작자에게 이용계약에 따른 서비스를 제공하여야 하며, 그 내용 또는 이행수준은 이용신청시 앱 창작자가 선택한 요금제가 제공하는 서비스 내용에 따릅니다.
② Ai.app은 서비스의 내용 또는 그 이행수준 변경 시그 내용이 앱 창작자에게 불리하거나 중요한 경우에는 앱 창작자의 동의를 얻어야 합니다.
③ Ai.app은 서비스의 원활한 사용을 위해 앱 창작자에게 제품, 신규 기능, 활용 방법 등에 대한 정보성 안내를 이메일 등으로 제공할 수 있습니다.
④ Ai.app과 앱 창작자는 서로 간의 문의나 요청에 대응하기 위해 이를 처리하는 담당부서 및 담당자의 이름과 연락처를 정하여 알려주어야 합니다.
담당부서 : 마케팅 팀
담당자 : 김소원
이메일 및 번호 : <mbaas.help@gmail.com​>, <010-7640-4707> <FAX : 050-4466-0300>

제12조(서비스 이용 요금)

① 앱 창작자는 유료 서비스 이용 신청시 선택한 부가서비스에 따라 매 이용 기간의 이용료를 지급하여야 한다. Ai.app은 요금제의 종류, 단가, 과금 방식, 정기 결제 방식 등 이용요금의 세부 내역을 홈페이지에 게시한다.
② 유료 서비스에는 「콘텐츠산업진흥법」, 「전자상거래등에서의소비자보호에관한법률」등에서 규정하는 청약철회가 불가능한 서비스가 포함되어 있으므로, 별도의 계약서에 명시되지 않은 한 앱 창작자의 청약철회권이 제한되며, 앱 창작자의 일방적인 계정 탈퇴 또는 프로그램 삭제 등에도 불구하고 이용요금 지급의무는 소멸하지 않는다.
③ 앱 창작자는 서비스 이용요금에 관한 모든 세금에 대한 책임이 있다.
④ Ai.app은 본 서비스 제공을 위하여 앱 창작자와 별도의 개별계약을 체결할 수 있고, 별도의 개별계약이 다르게 명시하지 않는 한, 이용계약의 종료일에 상관없이 Ai.app의 가격정책 변동에 따라 요금이 인상될 시 즉시 다음 청구 기간에 반영한다.
⑤ 서비스 사용료는 템플릿 기반 앱 생성 후 3개월 까지는 무료이며, 3개월 이후부터 사용료가 청구된다.

제13조(이용요금의 청구와 지급)

① Ai.app은 과금 시점을 기준으로 이용기간 동안 발생할 예정인 이용요금을 청구하고 지급청구서 또는 전자세금계산서를 발송하여야 한다.
② 앱 창작자는 지급청구서 혹은 전자세금계산서의 내용에 이의가 없으면 기재된 지급기일까지 청구된 요금을 지급하여야 한다. 다만 앱 창작자의 책임 없는 사유로 서비스를 이용하지 못한 경우에는 그 기간 동안의 이용요금 지급의무를 면한다
③ 앱 창작자는 청구된 이용요금에 이의가 있으면 청구서가 도달한 날로부터 4일 이내에 이의를 신청할 수 있고, Ai.app은 이의신청을 접수한 날로부터 3일 이내에 그 처리결과를 앱 창작자에게 통지하여야 한다.
④ 앱 창작자는 지급과 관련하여 앱 창작자가 입력한 정보가 정확한지 여부를 확인해야 하며, 입력 정보와 관련하여 발생한 책임과 불이익은 앱 창작자가 전적으로 부담한다.
⑤ Ai.app과 고의 및 중대한 과실이 있거나 Ai.app이 인정한 사유에 의한 경우를 제외하고 이용요금 미납으로 인해 발생하는 모든 문제에 대한 책임은 앱 창작자에게 있다.

제13조의 2(결제수단)
① 유료 서비스에서 사용할 수 있는 이용요금 결제수단은 다음 각 호와 같다.
1. 카드결제
2. 현금결제
② 전항에도 불구하고, 정기결제형 유료서비스의 이용을 신청하는 앱 창작자가 사용할 수 있는 이용요금 결제수단은 신용카드를 이용한 결제로 제한된다. 앱 창작자는 정기결제를 위하여 서비스에 결제수단(신용카드) 및 결제 정보를 입력하고 이를 저장할 수 있으며, 저장된 결제수단 및 결제 정보는 정기결제만을 위한 목적으로 사용된다.
③ 앱 창작자는 타인의 결제수단을 임의로 사용할 수 없다. 타인의 결제수단을 임의 사용함으로써 발생하는 Ai.app, 결제수단의 적법한 소유자, 기타 해당 결제와 관련된 제3자의 손실이나 손해에 대한 책임은 전부 앱 창작자에게 있다.
④ 유료서비스 이용을 위한 결제를 위하여 앱 창작자가 입력한 정보가 부정확하여 발생한 문제에 대한 책임과 불이익은 앱 창작자가 전적으로 부담하여야 한다.
⑤ 앱 창작자는 유료서비스 이용요금 결제 시 정당하고, 적법한 사용권한을 가지고 있는 결제수단을 사용하여야 하며, Ai.app은 그 여부를 확인할 수 있으며, 앱 창작자가 사용한 결제 수단의 적법성 등에 대한 확인이 완료될 때까지 거래 진행을 중지하거나 해당 거래를 취소할 수 있다.

제13조의 3(정기결제 및 정기결제형 유료서비스의 제공)
① 앱 창작자는 정기결제형 유료서비스를 이용하기 위하여 전조 제2항에 따라 해당 서비스에 결제수단 및 결제 정보를 입력하고 저장하여야 하며, 그에 따라 이용요금이 정기결제일(정기결제형 유료서비스의 최초 결제일 또는 앱 창작자가 임의로 지정한 날짜를 의미하며, 이하 동일하다)에 자동으로 결제된다. 단, 특정 월에 정기결제일에 해당하는 날짜가 존재하지 않을 경우 해당 월에 한하여 말일에 결제가 진행된다.
② Ai.app은 정기결제가 이루어지면 회원에게 결제 내용을 제13조에 따른 방법으로 고지한다.
③ 등록된 결제수단의 유효기간 만료 등 여하의 사유로 정기결제일에 이용요금의 결제가 정상적으로 이루어지지 않는 경우 “정기결제 유료서비스”의 이용이 중단될 수 있으며, 이러한 상황이 지속되는 경우 채무이행의 거절로 보고 Ai.app이 “유료 서비스” 이용계약을 해지할 수 있다.

제14조(이용요금의 정산 및 반환)

① Ai.app은 앱 창작자가 이용요금을 과·오납한 때에는 이를 반환하거나 다음 달 이용금액에서 정산하여야 하고, 앱 창작자가 이용요금을 체납한 때에는 최초 납입기일 다음날부터 완납시까지 지체일당 체납금액의 (1,000)분의 (3)을 곱하여 가산금을 징수할 수 있다.
② 앱 창작자가 Ai.app의 귀책 사유로 인한 서비스의 중대한 장애로 인하여 서비스를 사용할 수 없는 경우 이미 요금이 납부된 상황일 시 Ai.app에게 장애 발생 시점부터 장애해결 후 정상 이용이 가능해진 시점까지 이용요금의 반환을 청구할 수 있다.

제15조(서비스 이용의 제한 및 정지)

① Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에 서비스 이용을 제한 혹은 정지할 수 있으며, 그 사유가 해소되면 지체 없이 서비스 제공을 재개하여야 한다.
1. 앱 창작자가 정당한 사유 없이 이용요금을 연체하여 체납금 및 가산금의 이행을 최고받은 후 14일 이내에 이를 납입하지 않는 경우
2. 앱 창작자 혹은 최종이용자가 제3자에게 서비스를 임의로 제공하는 경우
3. 앱 창작자 혹은 최종이용자가 시스템 운영이나 네트워크 보안 등에 심각한 장애 혹은 전자적 침해행위로 데이터의 손상, 서버정지 등을 초래하거나 그밖에 이 계약의 규정에 위반하거나 할 우려가 있는 행위를 한 경우
4. 기타 관련 법령에 위반하거나 Ai.app의 업무를 방해하는 행위를 하는 경우
② Ai.app은 제1항 1호에 따른 서비스를 정지하기 전 (14)일까지 그 사실을 앱 창작자에게 통지하고 이의신청의 기회를 주어야 한다. 다만, 앱 창작자의 책임 있는 사유로 통지를 할 수 없는 때에는 그러하지 아니하다.
③ Ai.app은 제1항 2, 3, 4호에 따른 서비스 이용 제한 혹은 정지는 사전 통지 없이 진행할 수 있으며, Ai.app은 진행 후 그 사실을 앱 창작에게 지체 없이 통지하여야 한다.
④ Ai.app이 제1항 각 호에 따라 서비스를 정지한 경우에는 특별한 사유가 없으면 앱 창작자가 그 기간 동안의 이용요금을 납부하여야 한다.

제16조(서비스 제공의 중단)

① Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에 서비스 제공을 중단할 수 있으며, 그 사유가 해소되면 지체 없이 서비스 제공을 재개하여야 한다.
1. 서비스 개선을 위한 시스템 개선, 설비의 증설·보수·점검, 시설의 관리 및 운용 등의 사유로 부득이하게 서비스를 제공할 수 없는 경우
2. 해킹 등 전자적 침해 사고나 통신사고 등 예상하지 못한 서비스의 불안정성에 대응하기 위하여 필요한 경우
3. 천재지변, 정전, 서비스 설비의 장애 등으로 인하여 정상적인 서비스 제공이 불가능한 경우
② Ai.app은 제1항에 따라 서비스를 중단할 수 있으나, 중단 후에는 지체 없이 그 사실을 앱 창작자에게 통지하여야 한다.
③ 제2항에 따른 통지에는 중단기간이 포함되어야 하고, Ai.app이 그 기간을 초과한 경우에는 이용요금에서 초과기간에 대한 금액을 공제한다.
④ 앱 창작자가 제1항 각호에 정한 사유의 발생에 대하여 책임이 없는 경우에는 중단기간 동안의 이용요금에 대한 납부의무를 면하거나 중단기간만큼 이용기간을 추가 부여받을 수 있다.

제5장 서비스의 이용기간 및 종료


제17조(서비스 이용기간 자동갱신)

① 본 부가서비스의 이용 기간은 앱 창작자가 Ai.app과 개별계약 체결 시 선택한 요금제 및 이용 기간에 따른다.

제18조(앱 창작자의 서비스 해제 및 해지)

① 앱 창작자는 다음 각 호의 어느 하나에 해당하는 사유가 있는 경우에는 해당 서비스를 처음 제공받은 날부터 3월 이내 또는 그 사실을 알았거나 알 수 있었던 날부터 30일 이내에 이 계약을 해제할 수 있다.
1. 이 계약에서 약정한 서비스가 제공되지 않는 경우
2. 제공되는 서비스가 표시 · 광고 등과 상이하거나 현저한 차이가 있는 경우
3. 그밖에 서비스의 결함으로 정상적인 이용이 불가능하거나 현저히 곤란한 경우
② 앱창작자는 다음 각 호의 어느 하나에 해당하는 경우에 계약을 해지할 수 있다.
1. Ai.app이 서비스 제공 중에 파산 등의 사유로 계약상의 의무를 이행할 수 없거나 그 의무의 이행이 현저히 곤란하게 된 경우
2. Ai.app이 약정한 서비스계약의 내용에 따른 서비스 제공을 다하지 않는 경우
③ 제1항과 제2항에 따라 계약을 해지하고자 하는 때에는 Ai.app에게 해지 예정일 (30)일 전까지 그 사유를 통지하고 이의 신청의 기회를 주어야 한다. 다만 Ai.app의 책임 있는 사유로 통지를 할 수 없는 때에는 사전 통지와 이의신청의 기회 제공을 면한다.
④ 앱 창작자가 본 조에 따라 유료 서비스 이용계약을 해제 또는 해지하는 경우 Ai.app은 앱 창작자에게 해지예정일로부터 남은 기간 동안의 이용요금을 일할계산하여 환불한다.

제19조(Ai.app의 서비스 해제 및 해지)

① Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에 계약을 해제할 수 있다.
1. Ai.app이 서비스를 개시하여도 앱 창작자가 계약의 목적을 달성할 수 없는 경우
2. 계약체결 후 서비스가 제공되기 전에 앱 창작자 파산 등의 사유로 계약상의 의무를 이행할 수 없거나 그 의무의 이행이 현저히 곤란하게 된 경우
② Ai.app은 다음 각 호의 어느 하나에 해당하는 경우에 계약을 해지할 수 있다.
1. 앱 창작자가 제10조(앱 창작자의 의무)에서 정한 앱 창작자의 의무를 위반한 경우 혹은 다음 각 목의 어느 하나에 해당하는 경우
- 가. 앱 창작자가 서비스 이용을 정지당한 후 월 이용요금을 기준으로 (3)회 이상 이용요금의 지급을 연체한 경우
- 나. 앱 창작자가 Ai.app의 동의 없이 계약상의 권리 및 의무를 제3자에게 처분한 경우
2. 제14조(서비스 이용의 제한 및 정지)에 따라 서비스의 이용이 제한된 앱 창작자가 상당한 기간 동안 해당 사유를 해소하지 않는 경우
3. 사업의 종료에 따라 서비스를 종료하는 경우
③ Ai.app이 제2항에 따라 계약을 해지하고자 하는 때에는 앱 창작자에게 (30)일 전까지 그 사유를 통지하고 이의신청의 기회를 주어야 한다. 다만, 앱 창작자의 책임 있는 사유로 통지를 할 수 없는 때에는 사전통지와 이의신청의 기회제공을 면한다.
④ Ai.app은 앱 창작자가 고의 또는 중대한 과실로 Ai.app에게 손해를 입힌 경우에는 사전 통지 없이 계약을 해지할 수 있으며, Ai.app은 해지 후 그 사실을 앱 창작자에게 지체 없이 통지하여야 한다.
⑤ 제2항 제3호 및 제4항에 따른 계약 해지는 앱 창작자에 대한 손해배상의 청구에 영향을 미치지 아니한다.
⑥ Ai.app이 계약을 해지하는 경우에는 앱 창작자에게 서면, 전자우편 또는 이에 준하는 방법으로 다음 각 호의 사항을 통지하여야 한다.
1. 해지사유
2. 해지일
3. 환급비용

제7장 Ai.app의 면책 등

제20조(Ai.app의 면책)

① Ai.app은 본 서비스를 “있는 그대로 (As Is)” 제공하며 앱 창작자가 서비스를 이용하여 기대하는 이익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않는다.
② Ai.app은 Ai.app의 고의 및 중대한 과실이 없는 한 다음 각 호에서 정의한 손해에 대하여 어떠한 책임도 부담하지 않는다.
1. 무료체험서비스, 테스트, 시범 운영 서비스 등 Ai.app에게 비용을 지불하지 않은 서비스의 이용으로 인한 손해
2. 최종이용자 간, 앱 창작자와 최종이용자 간, 최종이용자와 제3자 간, 앱 창작자와 제3자 간에 발생한 분쟁으로 인한 손해
3. 천재지변 또는 이에 준하는 불가항력의 상태에서 발생한 손해
4. Ai.app의 통제범위를 벗어난 외부 네트워크 및 장비 등으로 인하여 발생한 손해
5. Ai.app이 제 17 조 제 1 항의 사유로 서비스를 제한·중단하여 발생한 손해
6. 앱 창작자 및 최종사용자의 귀책사유로 인하여 발생한 손해
7. 제 3자가 서비스의 접속 및 전송을 방해하거나 서비스를 중단시켜 발생한 손해
8. 제 3자가 악성 프로그램을 전송 또는 유포함으로써 발생하는 손해
9. 제 3자에 의한 앱 창작자 및 최종이용자의 계정 또는 Ai.app 서버에 대한 승인되지 않은 접속 및 이용을 원인으로 발생한 손해
10. 전송된 데이터의 생략, 누락, 파괴 등으로 발생한 손해
11. 앱 창작자의 고의 또는 과실이 없는 사유로 인해 발생한 손해
③ 관련 법령에 의하여 허용되는 최대한의 범위 내에서, Ai.app, Ai.app 및 그 제휴사의 임원, 이사, 파트너, 직원, 대리인 및 고문 (통칭하여 “Ai.app 측”)은 비록 그러한 손실 또는 손해의 가능성에 앞서 조언을 하였더라도 본 서비스의 이용과 관련한 상실이익, 상실수입, 상실예금 기타 간접적, 징벌적, 예외적 손해에 대하여 어떠한 방식으로도 책임을 부담하지 않는다.

제21조(앱 창작자에 대한 통지)

① Ai.app은 다음 각 호의 어느 하나에 해당하는 사유가 발생한 경우에는 앱 창작자가 미리 지정한 전화 또는 휴대전화로 문자메시지 또는 우편(전자우편 포함)의 발신, 서비스 접속화면 게시 등의 방법으로 앱 창작자에게 알려야 한다.
1. 침해사고
2. 최종이용자 정보의 유출
3. 서비스의 중단
4. 서비스의 종료
5. 그밖에 앱 창작자의 서비스 이용에 중대한 영향을 미치는 사항
② Ai.app은 제1항 각 호 중 어느 하나에 해당하는 사유가 발생한 경우에는 그 사실을 지체 없이 앱 창작자에게 알려야 한다. 다만, 다음 각 호의 경우는 예외로 한다.
1. (14)일 전에 사전 예고를 하고 서비스를 중단한 경우
2. 30일 전에 서비스를 변경하거나 종료하도록 한 경우
3. 30일 전에 사업을 폐지하거나 종료하도록 한 경우
③ Ai.app은 제1항 제1호에서 제3호까지의 사유가 발생한 경우에 지체 없이 다음 각 호의 사항을 해당 앱 창작자에게 알려야 한다. 다만, 제2호의 발생 원인을 바로 알기 어려운 경우에는 나머지 사항을 먼저 알리고, 발생 원인이 확인되면 이를 지체 없이 해당 앱 창작자에게 알려야 한다.
1. 발생내용
2. 발생원인
3. Ai.app의 피해 확산 방지 조치 현황
4. 앱 창작자의 피해예방 또는 확산방지방법
5. 담당부서 및 연락처
담당부서 : 마케팅 팀
담당자 : 김소원
이메일 및 번호 : <mbaas.help@gmail.com​>, <010-7640-4707> <FAX : 050-4466-0300>
제22조(양도 등의 제한)
Ai.app와 앱 창작자는 이 계약에 따른 권리와 의무의 전부 또는 일부를 상대방의 사전 동의 없이 제3자에게 양도 또는 담보로 제공할 수 없다. 앱 창작자가 최고관리자 권한을 다른 사내 담당자에게 양도하는 것은 계약의 권리와 의무 양도와 관계 없다.
제23조(준거법)
이 계약의 성립, 효력, 해석 및 이행과 관련하여서는 대한민국법을 적용한다.`

export const MARKETING_AGREE = `Ai.App 마케팅 정보 수신 동의

<주식회사 엠바스>가 운영하는 Ai.App 서비스에서 개인정보 보호법 및 정보통신망 이용촉진 정보보호 등에 관한 법률 등 관계 법령에 따라 광고성 정보를 전송하기 위해 이용자의 사전 수신 동의를 받고 있습니다. 개인정보 보호법 제22조 제5항에 의거하여 선택정보 사항에 대해서 기재하지 않아도 서비스를 이용하실 수 있습니다.

1. 마케팅 및 광고에의 활용

신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
- 맞춤 서비스 제공
뉴스레터 발송, 새로운 기능의 안내
광고성 정보 제공

2. 마케팅 정보 제공
(주)엠바스는 서비스를 운용하는 과정에서 각종 정보를 푸시 알림, 이메일, SMS 및 카카오 알림톡 등의 방법으로 회원에게 정보를 제공할 수 있습니다. 단, 광고성 정보 이외에 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공합니다.

3. 수신동의 및 철회
수신 동의 이후에도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사에서 제공하는 기본적인 서비스를 이용할 수 있으나 회사가 제공하는 마케팅 정보를 수신하지 못할 수 있습니다. 고객이 본 수신 동의를 철회하고자 할 경우 1:1 문의를 통하여 수신 동의 철회를 요청할 수 있습니다. 또한 마케팅 정보 수신에 새롭게 동의하고자 할 경우 1:1 문의를 통해 동의하실 수 있습니다. 
`

export const PERSONAL_AGREE = `AiApp 개인정보 처리방침

<(주)엠바스>('www.aiapp.help'이하 'Ai.app')는 「개인정보 보호법」 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.

본 개인정보처리방침의 목차는 아래와 같습니다.


관계법령이 요구하는 개인정보처리방침의 필수 사항과 Ai.app 자체적으로 이용자 개인정보 보호에 있어 중요하게 판단하는 내용을 포함하였습니다.

제1조(개인정보의 처리 목적)
제2조(개인정보의 처리 및 보유 기간)
제3조(처리하는 개인정보의 항목)
제4조(개인정보의 제3자 제공)
제5조(개인정보의 파기절차 및 파기방법)
제6조(미이용자의 개인정보 파기 등에 관한 조치)
제7조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
제8조(개인정보의 안전성 확보조치에 관한 사항)
제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)
제10조(추가적인 이용·제공 판단기준)
제11조(개인정보 보호책임자에 관한 사항)
제12조(개인정보의 열람청구를 접수·처리하는 부서)
제13조(정보 주체의 권익침해에 대한 구제 방법)
제14조(개인정보 처리방침 변경)

○ 이 개인정보처리방침은 2023년 3월 31일부터 적용됩니다.

제1조(개인정보의 처리 목적)

< (주)엠바스 >('mbaas.kr'이하 'Ai.app')는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

1. 홈페이지 회원가입 및 관리

회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.

2. 민원사무 처리

민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.

3. 재화 또는 서비스 제공

서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.


제2조(개인정보의 처리 및 보유 기간)

① < (주)엠바스 >는 법령에 따른 개인정보 보유·이용 기간 또는 정보 주체로부터 개인정보를 수집시에 동의받은 개인정보 보유·이용 기간 내에서 개인정보를 처리·보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.


1. <홈페이지 회원가입 및 관리>

<홈페이지 회원가입 및 관리>와 관련한 개인정보는 수집·이용에 관한 동의일로부터 <3년>까지 위 이용목적을 위하여 보유·이용됩니다.
보유근거 : 신용정보의 수집/처리 및 이용 등에 관한 기록 법령에 의거
관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
예외사유 : 계약/또는 청약 철회 등에 관한 기록 법령에 의거해 계약 및 철회 기록 5년 보유

2. <민원사무 처리>

<민원사무 처리>와 관련한 개인정보는 수집·이용에 관한 동의일로부터 <3년>까지 위 이용목적을 위하여 보유·이용됩니다.
보유근거 : 소비자의 불만 또는 분쟁 처리에 관한 기록 법령에 의거
관련법령 : 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년

3. <재화 또는 서비스 제공>

<재화 또는 서비스 제공>와 관련한 개인정보는 수집·이용에 관한 동의일로부터 <5년>까지 위 이용목적을 위하여 보유·이용됩니다.
보유근거 : 대금결제 및 재화 등의 공급에 관한 기록 법령에 의거
관련법령 : 대금결제 및 재화 등의 공급에 관한 기록 : 5년

제3조(처리하는 개인정보의 항목)

< (주)엠바스 >는 다음의 개인정보 항목을 처리하고 있습니다.
< 홈페이지 회원가입 및 관리 >
필수항목 : 이메일, 로그인ID, 비밀번호, 휴대전화번호, 이름
선택항목 : 회사명,직책, 부서, 회사 주소, 회사 대표 이메일, 신용카드정보, 은행계좌정보

제4조(개인정보의 제3자 제공)

① < (주)엠바스 >는 원칙적으로 정보주체의 개인정보를 수집수집·이용 목적으로 명시한 범위 내에서 처리하며, 다음의 경우를 제외하고는 정보주체의 사전 동의 없이는 본래의 목적 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다.
개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
② < (주)엠바스 >는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
1. 개인정보를 제공받는 자 : ㈜갤럭시아, ㈜오픈모바일 ㈜ OOO
제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 로그인ID, 생년월일, 이름, 회사전화번호, 회사명
제공받는 자의 보유·이용기간: 3년

보유근거 : 소비자의 불만 또는 분쟁 처리에 관한 기록 법령에 의거

① < (주)엠바스 >는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
1. 법령 근거 :
2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
1. 파기절차
< (주)엠바스 > 은(는) 파기 사유가 발생한 개인정보를 선정하고,
< (주)엠바스 > 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
2. 파기방법
전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.


제6조(미이용자의 개인정보 파기 등에 관한 조치)

① <(주)엠바스>은(는) 1년간 서비스를 이용하지 않은 이용자는 휴면계정으로 전환하고, 개인정보를 별도로 분리하여 보관합니다. 분리 보관된 개인정보는 1년간 보관 후 지체없이 파기합니다.
② <(주)엠바스>은(는) 휴면 전환 30일 전까지 휴면예정 회원에게 별도 분리 보관되는 사실 및 휴면 예정일, 별도 분리 보관하는 개인정보 항목을 이메일, 문자 등 이용자에게 통지 가능한 방법으로 알리고 있습니다.
③ 휴면계정으로 전환을 원하지 않으시는 경우, 휴면계정 전환 전 서비스 로그인을 하시면 됩니다. 또한, 휴면계정으로 전환되었더라도 로그인을 하는 경우 이용자의 동의에 따라 휴면계정을 복원하여 정상적인 서비스를 이용할 수 있습니다.

제7조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)

① 정보주체는 (주)엠바스에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
② 제1항에 따른 권리 행사는 (주)엠바스에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 (주)엠바스는 이에 대해 지체 없이 조치하겠습니다.
③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.
④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
⑥ (주)엠바스는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.

제8조(개인정보의 안전성 확보조치에 관한 사항)

< (주)엠바스 >는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.

1. 정기적인 자체 감사 실시
개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.

2. 개인정보 취급 직원의 최소화 및 교육
개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.

3. 내부 관리 계획의 수립 및 시행
개인정보의 안전한 처리를 위하여 내부 관리 계획을 수립하고 시행하고 있습니다.

4. 해킹 등에 대비한 기술적 대책
<(주)엠바스>('Ai.app')는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.

5. 개인정보의 암호화
이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.

6. 접속기록의 보관 및 위변조 방지
개인정보 처리시스템에 접속한 기록을 최소 1년 이상 보관·관리하고 있으며 다만, 5만 명 이상의 정보 주체에 관하여 개인정보를 추가하거나 고유 식별정보 또는 민감정보를 처리하는 경우에는 2년 이상 보관·관리하고 있습니다.
또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안 기능을 사용하고 있습니다.

7. 개인정보에 대한 접근 제한
개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.

제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)

서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 기록, 기기정보, 위치정보가 생성되어 수집될 수 있습니다. 또한 이미지 및 음성을 이용한 검색 서비스 등에서 이미지나 음성이 수집될 수 있습니다.
구체적으로 1) 서비스 이용 과정에서 이용자에 관한 정보를 자동화된 방법으로 생성하거나 이용자가 입력한 정보를 저장(수집)하거나, 2) 이용자 기기의 고유한 정보를 원래의 값을 확인하지 못 하도록 안전하게 변환하여 수집합니다. 또한 서비스 이용 과정 중 추후 위치정보서비스 개설 시 위치정보가 수집될 수 있으며, 엠바스에서 제공 예정인 위치기반 서비스에 대해서는 '위치기반서비스 이용약관'에서 자세하게 규정할 예정입니다.
이와 같이 수집된 정보는 개인정보와의 연계 여부 등에 따라 개인정보에 해당할 수 있고, 개인정보에 해당하지 않을 수도 있습니다.

정보 수집에 대한 추가 설명

IP(Internet Protocol) 주소란?
IP 주소는 인터넷 망 사업자가 인터넷에 접속하는 이용자의 PC 등 기기에 부여하는 온라인 주소정보 입니다. IP 주소가 개인정보에 해당하는지 여부에 대해서는 각국마다 매우 다양한 견해가 있습니다.
서비스 이용기록이란?
네이버 접속 일시, 이용한 서비스 목록 및 서비스 이용 과정에서 발생하는 정상 또는 비정상 로그 일체,메일 수발신 과정에서 기록되는 이메일주소, 친구 초대하기 또는 선물하기 등에서 입력하는 휴대전화번호, 스마트스토어 판매자와 구매자간 상담내역(네이버톡톡 및 상품 Q&A 게시글) 등을 의미합니다.
기기정보란?
본 개인정보처리방침에 기재된 기기정보는 생산 및 판매 과정에서 기기에 부여된 정보뿐 아니라, 기기의 구동을 위해 사용되는 S/W를 통해 확인 가능한 정보를 모두 일컫습니다. OS(Windows, MAC OS 등) 설치 과정에서 이용자가 PC에 부여하는 컴퓨터의 이름, PC에 장착된 주변기기의 일련번호, 스마트폰의 통신에 필요한 고유한 식별값(IMEI, IMSI), AAID 혹은 IDFA, 설정언어 및 설정 표준시, USIM의 통신사 코드 등을 의미합니다. 단, 네이버는 IMEI와 같은 기기의 고유한 식별값을 수집할 필요가 있는 경우, 이를 수집하기 전에 네이버도 원래의 값을 알아볼 수 없는 방식으로 암호화 하여 식별성(Identifiability)을 제거한 후에 수집합니다.
쿠키(cookie)란?
쿠키는 이용자가 웹사이트를 접속할 때에 해당 웹사이트에서 이용자의 웹브라우저를 통해 이용자의 PC에 저장하는 매우 작은 크기의 텍스트 파일입니다. 이후 이용자가 다시 웹사이트를 방문할 경우 웹사이트 서버는 이용자 PC에 저장된 쿠키의 내용을 읽어 이용자가 설정한 서비스 이용 환경을 유지하여 편리한 인터넷 서비스 이용을 가능케 합니다. 또한 방문한 서비스 정보, 서비스 접속 시간 및 빈도, 서비스 이용 과정에서 생성된 또는 제공(입력)한 정보 등을 분석하여 이용자의 취향과 관심에 특화된 서비스(광고 포함)를 제공할 수 있습니다. 이용자는 쿠키에 대한 선택권을 가지고 있으며, 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 네이버 일부 서비스의 이용에 불편이 있을 수 있습니다.

제10조(추가적인 이용·제공 판단기준)

< (주)엠바스 >는 ｢개인정보 보호법｣ 제15조 제3항 및 제17조 제4항에 따라 ｢개인정보 보호법 시행령｣ 제14조의2에 따른 사항을 고려하여 정보 주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.
이에 따라 < (주)엠바스 > 가(이) 정보 주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려하였습니다.
▶ 개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부
▶ 개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부
▶ 개인정보의 추가적인 이용·제공이 정보 주체의 이익을 부당하게 침해하는지 여부
▶ 가명 처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부
※ 추가적인 이용·제공 시 고려사항에 대한 판단기준은 사업자/단체 스스로 자율적으로 판단하여 작성·공개함

제11조 (개인정보 보호책임자에 관한 사항)

① <(주)엠바스>는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보 주체의 불만 처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
▶ 개인정보 보호 책임자
성명 : 김정현
직책 : CEO
직급 : 대표
연락처 : <mbaas.help@gmail.com​>,<010-7640-4707><FAX : 050-4466-0300>
※ 개인정보 보호 담당 부서로 연결됩니다.

▶ 개인정보 보호 담당부서
부서명 : 개발 1팀
담당자 : 안준형
연락처 : <mbaas.help@gmail.com​>,<010-7640-4707><FAX : 050-4466-0300>
② 정보 주체께서는 (주)엠바스 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만 처리, 피해구제 등에 관한 사항을 개인정보 보호 책임자 및 담당 부서로 문의하실 수 있습니다. (주)엠바스는 정보 주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.

제12조(개인정보의 열람청구를 접수·처리하는 부서)

정보 주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
< (주)엠바스 >는 정보 주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.

▶ 개인정보 열람청구 접수·처리 부서
부서명 : 개발 1팀
담당자 : 안준형
연락처 : 010-7640-4707

제13조(정보 주체의 권익침해에 대한 구제 방법)

정보 주체는 개인 정보 침해로 인한 구제를 받기 위하여 개인정보 분쟁조정위원회, 한국인터넷진흥원 개인정보 침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보 침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.

1. 개인정보 분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
2. 개인정보 침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)
4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)

「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.

제14조(개인정보 처리방침 변경)

① 이 개인정보처리방침은 2023년 3월 31일부터 적용됩니다`
